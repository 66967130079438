.App {
  max-width: 800px;
  /*max-height: 800px;*/
  margin: 0 auto;
  background-size: cover;
  background-position: top;
  text-align: center;
  padding-top: 0;
  height: 100%;
  position: relative;
}
body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 2px;
  height: 10px;
}
* {
  -moz-user-select: none;
  /* Firefox */
  -webkit-user-select: none;
  /* Chrome, Safari, Opéra depuis la version 15 */
  -ms-user-select: none;
  /* Internet explorer depuis la version 10 et Edge */
  user-select: none;
  /* Propriété standard */
}
input,
input:before,
input:after,
textarea,
textarea:before,
textarea:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}
::-moz-selection {
  /* Code for Firefox */
  color: initial;
  background-color: transparent !important;
}
.hidden {
  /* display: none;*/
}
.pwa-install-popup-ios {
  background-color: #273554 !important;
}
.pwa-install-popup-ios.pwa-install-popup-ios.safari-nav:after {
  border-top-color: #273554;
}
.pwa-install-popup-ios .pwa-install-popup-ios-content .right {
  text-align: left;
}
.pwa-install-popup-ios .pwa-install-popup-ios-content .right img.small {
  margin: -5px 5px !important;
}
.pwa-install-popup-ios .pwa-install-popup-ios-content .left {
  display: none;
}
@-webkit-keyframes blinker {
	from {opacity: 1.0;}
	to {opacity: 0.0;}
  }
  @keyframes blinker {
	from {opacity: 1.0;}
	to {opacity: 0.3;}
  }
  .blink{
	filter: brightness(1.75);
	  text-decoration: blink;
	  animation-name: blinker;
	  -webkit-animation-name: blinker;
	 animation-duration: 0.9s;
	  -webkit-animation-duration: 0.9s;
	  animation-iteration-count:infinite;
	  -webkit-animation-iteration-count:infinite;
	  animation-timing-function:ease-in-out;
	  -webkit-animation-timing-function:ease-in-out;
	  animation-direction: alternate;
	  -webkit-animation-direction: alternate;
  }